import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SVG from 'components/SVG';
import Button from 'components/Button';

import logoSvg from './img/logo.svg';
/* import arrowSvg from './img/arrow.svg'; */

import './Header.scss';

const b = block('header');

const Header = ({ location, history }) => {
  const locale = useSelector(state => state.locale.locale);
  const { role, isAuth, id, balance, currency } = useSelector(state => state.auth);

  /* const isNeedBackButton = useMemo(() => location.pathname !== '/', [location.pathname]); */

  /* const onClick = useCallback(() => {
    if (isNeedBackButton) history.goBack();
  }, [history, isNeedBackButton]); */

  // const text = locale[location.pathname.substring(0, location.pathname.lastIndexOf('/') === 0 ? location.pathname.length : location.pathname.lastIndexOf('/'))];
  /* let text = "";
  if (location.pathname === "/user-create") {
    text = locale[`/user-create-${role}`];
  } else if (location.pathname.lastIndexOf("/") === 0)
    text = locale[location.pathname.substring(0, location.pathname.length)];
  else
    text =
      locale[
        location.pathname.substring(0, location.pathname.lastIndexOf("/"))
      ]; */

  return (
    <div className={b()}>
      <div className={b('logo-wrapper')}>
        <Link to="/" className={b('logo')}>
          <SVG className={b('logo-icon')} svgProps={{ svg: logoSvg }} />
        </Link>
      </div>

      <div className={b('user')}>
        {isAuth ? (
          <Link className={b('user-content')} to="/">
            <div className={b('user-id')}>
              <span className={b('user-id-title')}>ID:</span>
              <span className={b('user-id-value')}>{id}</span>
            </div>
            <div className={b('user-balance')}>
              <span className={b('user-balance-value')}>{balance}</span>
              <span className={b('user-balance-currency')}>{currency}</span>
            </div>
          </Link>
        ) : (
          <div className={b('login-button-block')}>
            <Button link="/">{locale.login}</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Header);
