import { itemsOnPage } from './data';

export const convertSlotsBetHistoryFilters = ({ from, to, providers, statuses, gameTypes, page, userId }) => {
  const types2 = [...gameTypes.filter(gt => gt.active && gt.value !== 'all').map(gt => gt.value)].join('');
  const providers2 = [...providers.filter(p => p.active && p.value !== 'all').map(p => p.value)].join('');
  const statuses2 = [...statuses.filter(s => s.active && s.value !== 'all').map(s => s.value)].join('');
  return {
    dateFrom: from,
    dateTo: to,
    provider: providers2,
    status: statuses2,
    gameType: types2,
    page,
    count: itemsOnPage,
    target_id: userId,
  };
};
