export const errorStatusMessage = {
  defaultMessage: 'Request execution failed.',
  // auth
  1: 'User unauthorized.',
  2: 'Incorrect e-mail.',
  3: 'Such a user does not exist',
  4: 'Uncorrected password',
  5: 'The user is banned.',
  6: 'Invalid verification key.',
  7: 'Verification key expired.',
  8: 'The user has already passed the verification.',
  9: 'Request for recovery not found.',
  10: 'The request for recovery has expired.',
  11: 'Request for recovery not found.',
  14: 'Number of tariff changes per month exceeded',
  15: 'Invalid phone number.',
  18: 'Username must be at least 6 long and must not contain characters.',
  101: 'Request for recovery not found.',
  102: 'Request for recovery not found.',
  103: 'The transaction can not be completed.',
  104: 'Error displaying the withdrawal status of funds.',
  105: 'You can not withdraw funds. The conditions of the bonus program are not fulfilled. Apply to the online chat.',
  106: 'The sum of the output is less than the minimum.',
  // Site
  201: 'Cant get flatpage.',
  202: 'Cant get flatpage list.',
  231: 'Password must be at least 6 long and must not contain characters.',
  233: 'Password contains invalid characters',
  // Money
  501: 'Insufficient balance.',
  502: 'User does not exist.',
  // bet
  600: "Bet can't be placed.",
  601: 'Bet is not found.',
  602: 'Bet is already purchased.',
  603: 'The event is over.',
  605: 'You can not bet. Insufficient balance',
  606: 'You can not bet. The bonus percent is not allowed.',
  611: 'Bet is not found.',
  612: 'Event is not found.',
  613: 'Event already started.',
  614: 'Coefs were changed.',
  615: "Handicaps' sizes was changed.",
  616: 'Saving error+.',
  617: 'Bet is blocked by bookmaker.',
  618: 'Event already stoped.',
  619: 'Bet amount is more then maximum.',
  620: 'Information about the author of the bet not found.',
  621: 'Information about the content of the bet not found.',
  622: 'No money on player account.',
  623: 'Bet reception at the event stoped.',
  624: 'Bet reception at the variant bet stoped.',
  625: 'Bet is empty.',
  626: 'Incorrect number of ordinary.',
  627: 'In the express or system, the same event is used more than once.',
  628: 'Impossible events.',
  629: 'The limit on the maximum bet in a usual line has not been passed.',
  630: 'The limit on the maximum bet in a live line has not been passed.',
  631: 'The limit on the amout all placed bets in the usual line has not been passed.',
  632: 'The limit on the amout all placed bets in the live line has not been passed.',
  633: 'The limit on the maximum payout in a usual line has not been passed.',
  634: 'The limit on the maximum payout in a live line has not been passed.',
  635: 'The limit on the maximum bet in a usual line has not been passed.',
  636: 'The limit on the maximum bet in a live line has not been passed.',
  637: 'The limit on the amout all placed bets in the usual line has not been passed.',
  638: 'The limit on the amout all placed bets in the live line has not been passed.',
  639: 'The limit on the maximum payout in a usual line has not been passed.',
  640: 'The limit on the maximum payout in a live line has not been passed.',
  641: 'The limit on the maximum bet in a usual line has not been passed.',
  642: 'The limit on the maximum bet in a live line has not been passed.',
  643: 'The limit on the amout all placed bets in the usual line has not been passed.',
  644: 'The limit on the amout all placed bets in the live line has not been passed.',
  645: 'The limit on the maximum payout in a usual line has not been passed.',
  646: 'The limit on the maximum payout in a live line has not been passed.',
  647: 'The limit on the maximum bet in a usual line has not been passed.',
  648: 'The limit on the maximum bet in a live line has not been passed.',
  649: 'The limit on the amout all placed bets in the usual line has not been passed.',
  650: 'The limit on the amout all placed bets in the live line has not been passed.',
  651: 'The limit on the maximum payout in a usual line has not been passed.',
  652: 'The limit on the maximum payout in a live line has not been passed.',
  653: 'The limit on the maximum bet in a usual line has not been passed.',
  654: 'The limit on the maximum bet in a live line has not been passed.',
  655: 'The limit on the amout all placed bets in the usual line has not been passed.',
  656: 'The limit on the amout all placed bets in the live line has not been passed.',
  657: 'The limit on the maximum payout in a usual line has not been passed.',
  658: 'The limit on the maximum payout in a live line has not been passed.',
  659: 'The limit on the maximum payout for bet in bet in a usual line has not been passed.',
  660: 'The limit on the maximum payout for bet in bet event in a live line has not been passed.',
  661: 'The limit on the maximum payout for bet in event in a usual line has not been passed.',
  662: 'The limit on the maximum payout for bet in event in a live line has not been passed.',
  663: 'The limit on the maximum payout for bet in tourney in a usual line has not been passed.',
  664: 'The limit on the maximum payout for bet in tourney in a live line has not been passed.',
  665: 'The limit on the maximum payout for bet by user in a usual line has not been passed.',
  666: 'The limit on the maximum payout for bet by user in a live line has not been passed.',
  667: 'The limit on the maximum payout for bet in sport by user in a usual line has not been passed.',
  668: 'The limit on the maximum payout for bet in sport by user in a live line has not been passed.',
  669: 'User is not found.',
  670: 'Point is not found.',
  671: 'Tourney is not found.',
  672: 'Bet content is not found.',
  673: 'Bet is live, event is not live.',
  674: 'Event is live, bet is not live.',
  675: 'Money coef is bad.',
  676: 'One user put more than one bet in a unit of time.',
  677: 'The minimum bet is greater than the maximum bet.',
  678: 'Top-express is not found.',
  679: 'The maximum legal amount of payment for bet was exceeded..',
  680: 'Event is in the express only.',
  681: 'Event is in the single bet only.',
  682: 'Bad number of ordinars in the express (triple express).',
  683: 'Bad number of ordinars in the express (quadruple express).',
  684: 'Bad number of ordinars in the express (quinary express).',
  685: 'Bad number of ordinars in the express (senary express).',
  686: 'Bad number of ordinars in the express (express containing seven bets).',
  687: 'Bad number of ordinars in the express (express containing eight bets).',
  688: 'Bad number of ordinars in the express (express containing nine bets).',
  689: 'Size of the maximum coef  in the express was exceeded.',
  690: 'The amount of the bet exceeds the current limit of the point.',
  691: 'The limit for the number of this bet for this user.',
  692: "Unacceptable type of software, this software can't be betting.",
  693: 'Unacceptable currency.',
  694: 'The limit on placing of bets was exceeded.',
  999: 'Error by Betroute.',
  // Promocode
  2200: 'The promocode not found.',
  2201: 'The promocode has expired.',
  2202: 'Individual promotional code has already been activated.',
  2203: 'You have already activated this promotion code.',
  2204: 'You are trying to activate the promotional code of another partner.',
  2205: 'You are trying to activate your own promotional code.',
  2206: 'Unknown promocode type.',
  2207: 'You have already activated this percent promocode.',
  2208: 'You have already activated promocode.',
  2209: 'User has already canceled promotional code.',
  2210: 'The user has already filled the promotional code earlier.',
  2211: 'User is not a member of the bonus program.',
  2212: 'Promoode was blocked by admin.',
  2213: 'User has withdrawals after promocode activation.',
  2214: 'User is not satisfied with the terms of the bonus program.',
};
