import React, { useEffect, useRef } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SVG from 'components/SVG';

/* import spinner from './img/spinner.svg'; */
import logo from './img/logo.png';

import './Spinner.scss';

const b = block('spinner-mobile');

const SpinnerComponent = ({ isLoading, text, locale, size = 'small' }) => {
  const spinnerWrapperRef = useRef(null);
  const spinnerRef = useRef(null);

  let timeout;

  useEffect(() => {
    if (spinnerRef.current !== null && spinnerWrapperRef !== null) {
      if (isLoading) {
        spinnerRef.current.style = 'display: flex';
        spinnerWrapperRef.current.style = 'transition: 0s; transform: scale(1)';
      } else {
        spinnerWrapperRef.current.style = 'transition: 0.35s; transform: scale(0.2)';
        timeout = setTimeout(() => {
          if (spinnerRef.current.style) {
            spinnerRef.current.style = 'display: none';
          }
        }, 400);
      }
    }
  }, [isLoading]);
  useEffect(() => clearTimeout(timeout), []);

  return (
    <div className={b({ size })} ref={spinnerRef}>
      <div className={b('wrapper')} ref={spinnerWrapperRef}>
        <img className={b('loader')} src={logo} alt="spinner" />
        {/* <SVG className={b('loader')} svgProps={{ svg: spinner }} alt="spinner" /> */}
      </div>
    </div>
  );
};

SpinnerComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string,
  locale: PropTypes.object.isRequired,
  size: PropTypes.string,
};

export const Spinner = connect(state => ({ locale: state.locale.common }))(SpinnerComponent);
