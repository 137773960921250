import { initialState } from '../initial';
import { actionTypes } from '../actions';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return { ...state, actionProcessing: action.payload };

    case actionTypes.GET_SLOTS_BET_HISTORY_SUCCESS:
      return { ...state, history: action.payload.history, pages: action.payload.pageCount };

    case actionTypes.CLEAR_HISTORY:
      return {...initialState}

    default:
      return state;
  }
}
