import React, { useState, useCallback, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';

import BackBlock from 'components/BackBlock';

import { actions } from '../redux';
import TreeRow from './TreeRow/TreeRow';

import './Tree.scss';

const b = block('tree');

const Tree = () => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const usersDataList = useSelector(state => state.tree.users);

  const rows = useMemo(
    () =>
      usersDataList.map((row, index, array) => (
        <TreeRow
          key={row.userId}
          nesting={[row.userId]}
          row={row}
          lvl={1}
          isLastBranch={index === array.length - 1}
          isTotalLastBranch={index === array.length - 1}
        />
      )),
    [usersDataList],
  );

  useEffect(() => {
    dispatch(actions.getUsersByUserId());
    return () => dispatch(actions.getUsersByUserId());
  }, []);

  return (
    <div className={b()}>
      <div className={b('back-block')}>
        <BackBlock>{locale['/tree']}</BackBlock>
      </div>
      <div className={b('list')}>{rows}</div>
    </div>
  );
};

export default Tree;
