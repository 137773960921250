import React, {useCallback, useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {actions as betHistory} from 'features/betHistory';

import {statusItems} from './data';
import {convertBetHistoryFilters} from './convertBetHistoryFilters';

const useBetHistoryFilters = () => {
  const dispatch = useDispatch();
  const { history, pages } = useSelector(state => state.betHistory, shallowEqual);
  console.log(history)

  const [state, setState] = useState({
    from: new Date(dayjs().add(-1, 'month')),
    to: new Date(dayjs()),
    statuses: statusItems,
    page: 0,
    userId: '',
  });

  const { statuses, from, to, page, userId } = state;

  const write = useCallback(
    (value, type) => {
      const setter = {
        statuses: t => setState({ ...state, statuses: t }),
        from: t => setState({ ...state, from: t }),
        to: t => setState({ ...state, to: t }),
        userId: t => setState({ ...state, userId: t }),
      };
      setter[type](value);
    },
    [state],
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(
        betHistory.getBetHistory(
          convertBetHistoryFilters({
            from: dayjs(from).format('YYYY-MM-DD'),
            to: dayjs(to).add(1, 'day').format('YYYY-MM-DD'),
            statuses,
            page,
            userId,
          }),
        ),
      );
    },
    [statuses, from, to, page, userId, dispatch],
  );

  const onChangeStatus = useCallback(
    value => {
      write(value, 'statuses');
    },
    [write],
  );

  const onChangeFrom = useCallback(date => write(dayjs(date).format('YYYY-MM-DD'), 'from'), [write]);
  const onChangeTo = useCallback(date => write(dayjs(date).format('YYYY-MM-DD'), 'to'), [write]);
  const onPageChanged = useCallback(
    newPage =>
      setState({
        ...state,
        page: newPage,
      }),
    [state],
  );
  const onChangeUserId = useCallback(
    e => {
      write(e.target.value, 'userId');
    },
    [write],
  );
  // если айтэмы приходят под конкретную страницу и число страниц определяется бэком
  const pageCount = useMemo(() => pages, [pages]);
  const items = useMemo(
    () =>
      history.map((t, index) => ({
        ...t,
        key: `${Object.values(t).join('-')}_${index}_${page}`,
      })),
    [history, page],
  );

  // если айтэмы приходят для всех страниц
  /* const pageCount = useMemo(() => Math.ceil(history.length / itemsOnPage), [history]);
  const items = useMemo(
    () =>
      history.slice(page * itemsOnPage, page * itemsOnPage + itemsOnPage).map((t, index) => ({
        ...t,
        key: `${Object.values(t).join('-')}_${index}_${page}`,
      })),
    [history, page],
  ); */

  useEffect(() => {
    dispatch(
      betHistory.getBetHistory(
        convertBetHistoryFilters({
          from: dayjs(from).format('YYYY-MM-DD'),
          to: dayjs(to).add(1, 'day').format('YYYY-MM-DD'),
          statuses,
          page,
          userId,
        }),
      ),
    );
  }, [page]);

  return useMemo(
    () => ({
      items,
      pageCount,
      state,
      statusItems,
      onChangeStatus,
      onChangeFrom,
      onChangeTo,
      onPageChanged,
      onSubmit,
      onChangeUserId,
    }),
    [
      items,
      pageCount,
      state,
      onChangeStatus,
      onChangeFrom,
      onChangeTo,
      onPageChanged,
      onSubmit,
      onChangeUserId,
    ],
  );
};

export default useBetHistoryFilters;
