import React, { useCallback, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as slotsBetHistoryActions } from 'features/slotsBetHistory';

import { providerItems, gameTypeItems, statusItems, itemsOnPage } from './data';
import { convertSlotsBetHistoryFilters } from './convertSlotsBetHistoryFilters';

const useSlotHistoryFilters = () => {
  const dispatch = useDispatch();
  const { history, pages } = useSelector(state => state.slotsBetHistory, shallowEqual);

  const [state, setState] = useState({
    from: new Date(dayjs().add(-1, 'month')),
    to: new Date(dayjs()),
    providers: providerItems,
    statuses: statusItems,
    gameTypes: gameTypeItems,
    page: 0,
    userId: '',
  });

  const { providers, statuses, gameTypes, from, to, page, userId } = state;

  const write = useCallback(
    (value, type) => {
      const setter = {
        providers: t => setState({ ...state, providers: t }),
        statuses: t => setState({ ...state, statuses: t }),
        gameTypes: t => setState({ ...state, gameTypes: t }),
        from: t => setState({ ...state, from: t }),
        to: t => setState({ ...state, to: t }),
        userId: t => setState({ ...state, userId: t }),
      };
      setter[type](value);
    },
    [state],
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(
        slotsBetHistoryActions.getSlotsBetHistory(
          convertSlotsBetHistoryFilters({
            from: dayjs(from).format('YYYY-MM-DD'),
            to: dayjs(to).add(1, 'day').format('YYYY-MM-DD'),
            providers,
            statuses,
            gameTypes,
            page,
            userId,
          }),
        ),
      );
    },
    [providers, statuses, gameTypes, from, to, page, userId, dispatch],
  );

  const onChangeGameType = useCallback(
    value => {
      write(value, 'gameTypes');
    },
    [write],
  );
  const onChangeProvider = useCallback(
    value => {
      write(value, 'providers');
    },
    [write],
  );
  const onChangeStatus = useCallback(
    value => {
      write(value, 'statuses');
    },
    [write],
  );
  const onChangeFrom = useCallback(date => write(dayjs(date).format('YYYY-MM-DD'), 'from'), [write]);
  const onChangeTo = useCallback(date => write(dayjs(date).format('YYYY-MM-DD'), 'to'), [write]);
  const onPageChanged = useCallback(
    newPage =>
      setState({
        ...state,
        page: newPage,
      }),
    [state],
  );
  const onChangeUserId = useCallback(
    e => {
      write(e.target.value, 'userId');
    },
    [write],
  );

  // если айтэмы приходят под конкретную страницу и число страниц определяется бэком
  const pageCount = useMemo(() => pages, [pages]);
  const items = useMemo(
    () =>
      history.map((t, index) => ({
        ...t,
        key: `${Object.values(t).join('-')}_${index}_${page}`,
      })),
    [history, page],
  );

  // если айтэмы приходят для всех страниц
  /* const pageCount = useMemo(() => Math.ceil(history.length / itemsOnPage), [history]);
  const items = useMemo(
    () =>
      history.slice(page * itemsOnPage, page * itemsOnPage + itemsOnPage).map((t, index) => ({
        ...t,
        key: `${Object.values(t).join('-')}_${index}_${page}`,
      })),
    [history, page],
  ); */

  useEffect(() => {
    dispatch(
      slotsBetHistoryActions.getSlotsBetHistory(
        convertSlotsBetHistoryFilters({
          from: dayjs(from).format('YYYY-MM-DD'),
          to: dayjs(to).add(1, 'day').format('YYYY-MM-DD'),
          providers,
          statuses,
          gameTypes,
          page,
          userId,
        }),
      ),
    );
  }, [page]);

  return useMemo(
    () => ({
      items,
      pageCount,
      state,
      providerItems,
      gameTypeItems,
      statusItems,
      onChangeGameType,
      onChangeProvider,
      onChangeStatus,
      onChangeFrom,
      onChangeTo,
      onPageChanged,
      onSubmit,
      onChangeUserId,
    }),
    [
      items,
      pageCount,
      state,
      onChangeGameType,
      onChangeProvider,
      onChangeStatus,
      onChangeFrom,
      onChangeTo,
      onPageChanged,
      onSubmit,
      onChangeUserId,
    ],
  );
};

export default useSlotHistoryFilters;
