import React, { useEffect } from 'react';
import block from 'bem-cn';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { actions as authAction } from './features/Auth';
import Notify from './features/notify/view';
import CreateUser from './features/users/view/CreateUser';
import UsersList from './features/users/view/UsersList';
import UserEdit from './features/users/view/UserEdit';
import ChangePas from './features/ChangePas';
import { Total } from './features/Total';
/* import { TotalBalance } from './features/TotalBalance'; */
import { Tree } from './features/tree';
import { Bets } from './features/bets';
import CashierJackpot from './features/cashierJackpot';
import SlotsBetHistory from './features/slotsBetHistory/view';
import TransactionHistory from './features/transaction/view/TransactionHistory';
import BetHistory from './features/betHistory/view';

import Footer from 'components/Footer';
import Header from 'components/Header';
import AsyncPollBalance from 'components/AsyncPoll/AsyncPollBalance';

import { Locale } from 'features/locale';
import { Auth } from 'features/Auth';
import Subheader from 'components/Subheader';
import Menu from 'components/Menu';

import './App.scss';

const b = block('app');

const App = ({ location }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const isAuth = useSelector(state => state.auth.isAuth);
  const user = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);

  return (
    <div className={b()}>
      <Notify />
      <Header />
      {/* {isAuth && <Subheader user={user} getBalance={authAction.getBalance} />} */}
      {isAuth ? (
        <Switch>
          <Route exact key="/user-create" path="/user-create" component={CreateUser} />
          <Route exact key="/users-list" path="/users-list" component={UsersList} />
          <Route exact key="/total" path="/total" component={Total} />
          {/* <Route exact key="/totalBalance" path="/totalBalance" component={TotalBalance} /> */}
          <Route exact key="/user-edit/:id" path="/user-edit/:id" component={UserEdit} />
          <Route exact key="/transaction-history" path="/transaction-history" component={TransactionHistory} />
          <Route exact key="/change-pas" path="/change-pas" component={ChangePas} />
          <Route exact key="/tree" path="/tree" component={Tree} />
          <Route exact key="/casino-history" path="/casino-history" component={SlotsBetHistory} />
          <Route exact key="/bets" path="/bets" component={Bets} />
          <Route exact key="/cashier-jackpot" path="/cashier-jackpot" component={CashierJackpot} />
          <Route exact key="/bet-history" path="/bet-history" component={BetHistory} />
          <Route exact key="/" path="/" component={Menu} />
        </Switch>
      ) : (
        <Auth />
      )}
      <Switch>
        <Route exact key="/locale" path="/locale" component={Locale} />
      </Switch>
      {isAuth && location.pathname === '/' && <Footer locale={locale} isAuth={isAuth} />}
      <AsyncPollBalance />
    </div>
  );
};

export default withRouter(App);
