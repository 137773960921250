import EnPNG from './img/en.png';
import PtPNG from './img/pt.png';
import EsPNG from './img/es.png';

export const languages = {
  EN: 'en-US',
  PT: 'pt-pt',
  ES: 'es-es',
};

export const languagesWithIcons = {
  'en-US': { lang: languages.EN, icon: EnPNG, text: 'English' },
  'pt-pt': { lang: languages.PT, icon: PtPNG, text: 'Portuguesa' },
  'es-es': { lang: languages.ES, icon: EsPNG, text: 'Española' },
};

export const shortLanguages = {
  'en-US': 'en',
  'pt-pt': 'pt',
  'es-es': 'es',
};
