import React, { useState } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import arrowSvg from './img/arrow.svg';

import SVG from 'components/SVG';

import './BetHistoryItem.scss';

const b = block('bet-history-item-mobile');

const BetHistoryItem = ({ item, userId }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const [isExpressOpen, openExpress] = useState(false);

  const { amount, currency, date, id, totalCoef, isExpress, bets, status } = item;

  const express = isExpress > 0;
  const betType = express ? 'Express' : 'Ordinary';

  const TotalBet = () => (
    <div className={b('bet')}>
      <div className={b('block22')}>
        <div className={b('total')}>
          {locale.total}:<div className={b('coef')}>{totalCoef.toFixed(2)}</div>
        </div>
      </div>
      <div className={b('dotted')} />
    </div>
  );

  const BetBlock = ({ betStatus, tourneyName, betName, teams, coef, onlyTotal = false, withTotal = false }) => (
    <div className={b('bet')}>
      {onlyTotal ? (
        <TotalBet />
      ) : (
        <>
          <div className={b('block2')}>
            <div className={b('block2-item')}>
              <span className={b('block2-item-text1')}>{`${tourneyName}`}</span>
              <span className={b('block2-item-text2')}>{`${teams}`}</span>
              <span className={b('block2-item-text3')}>{`${betName}`}</span>
            </div>
            <div className={b('coef', { status: express ? betStatus : status })}>{coef.toFixed(2)}</div>
          </div>
          <div className={b('dotted')} />
          {withTotal && <TotalBet />}
        </>
      )}
    </div>
  );

  return (
    <div className={b({ express })}>
      <div className={b('block1')}>
        <div className={b('block1-item')}>
          <span className={b('block1-item-title')}>{`${locale.userId}`}</span>
          <span className={b('block1-item-value')}>{`${userId}`}</span>
        </div>
        <div className={b('block1-item')}>
          <span className={b('block1-item-title')}>{`${locale.betCode}`}</span>
          <span className={b('block1-item-value')}>{`${id}`}</span>
        </div>
        <div className={b('block1-item2')}>
          <span className={b('block1-item-title')}>{`${locale.betType}`}</span>
          <span className={b('block1-item-value')}>{`${betType}`}</span>
        </div>
        {express && (
          <div className={b('block1-item')} onClick={() => openExpress(!isExpressOpen)}>
            <SVG svgClassName={b('arrow', { active: isExpressOpen })} svgProps={{ svg: arrowSvg }} />
          </div>
        )}
      </div>
      <div className={b('dotted')} />
      {!express ? (
        bets.map(bet => (
          <BetBlock
            key={bet.betName}
            teams={bet.teams}
            betStatus={bet.status}
            betName={bet.betName}
            coef={bet.coef}
            tourneyName={bet.tourneyName}
          />
        ))
      ) : isExpressOpen ? (
        bets.map((bet, idx) => (
          <BetBlock
            key={bet.betName}
            teams={bet.teams}
            betStatus={bet.status}
            betName={bet.betName}
            coef={bet.coef}
            tourneyName={bet.tourneyName}
            withTotal={idx === bets.length - 1}
          />
        ))
      ) : (
        <BetBlock onlyTotal />
      )}

      <div className={b('block3')}>
        <div className={b('block3-item')}>
          <span className={b('block3-item-title')}>{`${locale.date}`}</span>
          <span className={b('block3-item-value')}>{dayjs(date).format('MMMM D, HH:mm')}</span>
        </div>
        <div className={b('block3-item')}>
          <span className={b('block3-item-title')}>{`${locale.bet}:`}</span>
          <span className={b('block3-item-value')}>{`${amount} ${currency}`}</span>
        </div>
        <div className={b('block3-item')}>
          <span className={b('block3-item-title')}>{`${locale.status}`}</span>
          {status === 1 ? (
            <span className={b('status', { type: status })}>{`${amount}`}</span>
          ) : (
            <span className={b('status', { type: status })}>{locale.betStatuses[status]}</span>
          )}
        </div>
      </div>
    </div>
  );
};

BetHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
};

export default BetHistoryItem;
