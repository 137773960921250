/* eslint-disable no-continue */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import { shallowEqual } from 'react-redux';
import ReactPaginate from 'react-paginate';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow.svg';

import './Paginator.scss';

const b = block('paginator-mobile');

const Paginator = ({ count, itemsOnPage, items, currentPage, onPageClick }) => {
  // const locale = useAppSelector(state => state.locale.common, shallowEqual);

  // // массив страниц с пропущенной активной страницей
  // const items2 = useMemo(() => {
  //   let result = [];
  //   for (let i = 0; i < count; i += 1) {
  //     if (currentPage === i) continue;
  //     result.push(i);
  //   }
  //   return result;
  // }, [count, currentPage]);

  // const canClickArrowLeft = useMemo(() => currentPage > 0, [currentPage]);
  // const canClickArrowRight = useMemo(() => currentPage < count - 1, [currentPage, count]);

  // const handleLeftArrowClick = useCallback(() => {
  //   if (canClickArrowLeft) onPageClick(currentPage - 1);
  // }, [canClickArrowLeft, currentPage, onPageClick]);

  // const handleRightArrowClick = useCallback(() => {
  //   if (canClickArrowRight) onPageClick(currentPage + 1);
  // }, [canClickArrowRight, currentPage, onPageClick]);

  // const pagesCount = useMemo(() => items2.length + 1, [items2]);
  // const currentPageNumber = useMemo(() => currentPage + 1, [currentPage]);

  // return (
  //   <div className={b()}>
  //     <div className={b('arrow-left-container')}>
  //       <SVG className={b('arrow-left')} svgProps={{ svg: arrowSVG }} onClick={handleLeftArrowClick} />
  //     </div>
  //     <div className={b('item')}>{currentPageNumber}</div>
  //     <div className={b('arrow-right-container')}>
  //       <SVG className={b('arrow-right')} svgProps={{ svg: arrowSVG }} onClick={handleRightArrowClick} />
  //     </div>
  //   </div>
  // );

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsOnPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  }, [itemOffset, itemsOnPage]);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsOnPage) % items.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
    onPageClick(event.selected);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        previousLabel={
          <div className={b('arrow-left-container')}>
            <SVG className={b('arrow-left')} svgProps={{ svg: arrowSVG }} />
          </div>
        }
        nextLabel={
          <div className={b('arrow-right-container')}>
            <SVG className={b('arrow-right')} svgProps={{ svg: arrowSVG }} />
          </div>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        marginPagesDisplayed={2}
        pageCount={count || 1/* pageCount */}
        renderOnZeroPageCount={/* null */ undefined}
        containerClassName={b()}
        pageClassName={b('page-item')}
        pageLinkClassName={b('page-link')}
        previousClassName={b('page-item')}
        previousLinkClassName={b('page-link')}
        nextClassName={b('page-item')}
        nextLinkClassName={b('page-link')}
        breakClassName={b('page-item')}
        breakLinkClassName={b('page-link')}
        activeClassName={b('active')}
      />
    </>
  );
};

export default Paginator;
