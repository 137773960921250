export class BetHistoryConverter {
  convertBetHistory = d => {
    return {
      history:
        d?.coupons?.map(t => ({
          amount: t?.amount,
          date: t?.created_at,
          totalCoef: t?.total_coeff,
          isExpress: t?.is_express,
          id: t?.coupon_id,
          currency: t?.currency,
          status: t?.status,
          bets: t?.bets?.map(bet=> {
            const { p1, p2, sportId, betName, champName } = bet?.additional_data
            return {
              coef: bet?.coeff,
              status: bet?.status,
              betName,
              sportId,
              tourneyName: champName ?? '',
              teams: `${p1} - ${p2}`
            }
          }) || []
        })) || [],
      pageCount: d?.pageCount,
    };
  };
}
