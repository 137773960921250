import BaseApi from '../BaseApi';
import { BetHistoryConverter, MOCK } from './BetHistoryConverter';

class BetHistoryApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new BetHistoryConverter();
  }

  getBetHistory = args => {
    return this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/line_history`,
      { ...args },
      null,
      this.converter.convertBetHistory,
    );
  };
}

export default BetHistoryApi;
