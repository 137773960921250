import React, { useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as historyActions } from 'features/betHistory';

import { itemsOnPage } from 'features/slotsBetHistory/helpers/data';

import useBetHistoryFilters from '../helpers/useBetHistoryFilters';
import Spinner from 'components/Spinner';
import BackBlock from 'components/BackBlock';
import Paginator from 'components/Paginator';
import BetHistoryItem from './BetHistoryItem';
import BetHistoryFilter from './BetHistoryFilter';

import './BetHistory.scss';

const b = block('bet-history-mobile');

const BetHistory = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { actionProcessing } = useSelector(state => state.slotsBetHistory, shallowEqual);
  const [isFiltersOpened] = useState(true);

  const filterData = useBetHistoryFilters();
  const { items, state, pageCount, onPageChanged } = filterData;
  const { page } = state;

  useEffect(() => {
    return () => dispatch(historyActions.clearHistory());
  }, []);

  const itemsElements = useMemo(
    () =>
      items.map(t => (
        <div key={t.key} className={b('item')}>
          <BetHistoryItem item={t} userId={state.userId} />
        </div>
      )),
    [items],
  );

  return (
    <>
      <BackBlock>{locale.betHistory2}</BackBlock>
      <div className={b()}>
        <Spinner isLoading={actionProcessing} size="big" />
        <div className={b('content')}>
          <div className={b('filters', { opened: isFiltersOpened })}>
            <BetHistoryFilter filterData={filterData} />
          </div>
          <div className={b('list')}>{itemsElements}</div>
          <div className={b('paginator-container')}>
            <div className={b('paginator')}>
              <Paginator
                count={pageCount}
                currentPage={page}
                onPageClick={newPage => onPageChanged(newPage)}
                itemsOnPage={itemsOnPage}
                items={items}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BetHistory;
