import React from 'react';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import { betFilters } from '../../data';

import InputDate from 'components/InputDate';
import Input from 'components/Input';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';

import './BetHistoryFilter.scss';

const b = block('bet-history-filter-mobile');

const BetHistoryFilter = ({ filterData }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const { state, onChangeStatus, onChangeFrom, onChangeTo, onSubmit, onChangeUserId } = filterData;

  const BetFilters = (
    <div className={b('checkboxes')}>
      {Object.keys(betFilters).map(key => (
        <>
          <p className={b('title')}>{locale[key]}</p>
          <div className={b('list')}>
            {betFilters[key].map(filter => (
              <label key={filter.name} className={b('checkbox-item')}>
                <div className={b('checkbox')}>
                  <CheckBox
                    name={filter.name}
                    checked={state.statuses.find(el => el.name === filter.name)?.active}
                    onChange={() => {
                      const updatedItems = state.statuses.map(el => ({
                        ...el,
                        active: filter.name === el.name ? !el.active : el.active,
                      }));
                      onChangeStatus(updatedItems);
                    }}
                  />
                </div>
                <span className={b('checkbox-item-text')}>{locale.betTypes[filter.name]}</span>
              </label>
            ))}
          </div>
        </>
      ))}
    </div>
  );

  return (
    <form className={b()} onSubmit={onSubmit}>
      <div className={b('items')}>
        <div className={b('item')}>
          <span className={b('item-label')}>{locale.userId}</span>
          <div className={b('item-input')}>
            <Input value={state.userId} onChange={onChangeUserId} type="number" placeholder={locale.userId} />
          </div>
        </div>
        {BetFilters}
        <div className={b('item-dates')}>
          <span className={b('item-dates-title')}>{locale.date}</span>
          <div className={b('item-dates-container')}>
            <span className={b('item-dates-label')}>{locale.from}</span>
            <span className={b('item-dates-label')}>{locale.to}</span>
            <div className={b('item-dates-input')}>
              <InputDate
                label={locale.from}
                dateFormat="yyyy-MM-dd"
                selected={new Date(state.from)}
                onChange={onChangeFrom}
              />
            </div>
            <div className={b('item-dates-input')}>
              <InputDate
                label={locale.to}
                dateFormat="yyyy-MM-dd"
                selected={new Date(state.to)}
                onChange={onChangeTo}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={b('button')}>
        <Button type="submit">
          <span className={b('button-text')}>{locale.showResults}</span>
        </Button>
      </div>
    </form>
  );
};

export default BetHistoryFilter;
