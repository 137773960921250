import React, { useEffect } from 'react';
import block from 'bem-cn';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';

import home from './img/home.svg';

import './Subheader.scss';
import { useSelector } from 'react-redux';

const b = block('subheader');

const Subheader = ({ getBalance, user }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);

  /* useEffect(() => {
    const value = setInterval(() => dispatch(getBalance()), 3000);
    return () => {
      clearInterval(value);
    };
  }, []); */

  return (
    <article className={b()}>
      {locale.home}
      {/* {`${user.name}: ${user.balance} ${user.currency}`}
      <Link to="/" className={b('home')}>
        <SVGInline svg={home} className={'image'} />
      </Link> */}
    </article>
  );
};

export default Subheader;
