import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Redirect } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input';
import { actions as authActions } from 'features/Auth';

import lock from './img/lock.svg';
import login from './img/login.svg';

import './Auth.scss';

const b = block('auth');

const Auth = () => {
  const dispatch = useDispatch();

  const [userName, changeUserName] = useState('');
  const [password, passwordUserName] = useState('');

  const locale = useSelector(state => state.locale.locale);
  const { isProcess, isAuth } = useSelector(state => state.auth);

  const signIn = useCallback(() => {
    if (!isProcess) {
      dispatch(authActions.login(userName, password));
    }
  }, [userName, password, isProcess, dispatch]);

  if (isAuth) return <Redirect to="/" />;
  return (
    <section className={b()}>
      <div className={b('title')}>{locale.login}</div>
      <div className={b('content')}>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.userName}:`}</div>
          <div className={b('field-input')}>
            <Input
              value={userName}
              onChange={e => changeUserName(e.currentTarget.value)}
              name="login"
              placeholder={locale.login}
            />
          </div>
        </div>
        <div className={b('field')}>
          <div className={b('field-title')}>{`${locale.password}:`}</div>
          <div className={b('field-input')}>
            <Input
              value={password}
              onChange={e => passwordUserName(e.currentTarget.value)}
              name="password"
              /* type="password" */
              placeholder={locale.password}
            />
          </div>
        </div>
        <div className={b('button')}>
          <Button onClick={signIn}>
            <span className={b('button-text')}>{locale.login}</span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Auth;
