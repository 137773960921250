import { itemsOnPage } from './data';

export const convertBetHistoryFilters = ({ from, to, statuses, page, userId }) => {
  const statuses2 = [...statuses.filter(s => s.active && s.value !== 'all').map(s => s.value)];
  return {
    from_date: from,
    to_date: to,
    status: statuses2.length ? statuses2 : null,
    page: page + 1,
    count: itemsOnPage,
    target_id: userId,
  };
};
