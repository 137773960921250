import React, { useEffect, useMemo, useCallback, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { actions as usersActions } from 'features/users/redux';

import { generatePasswordForUser } from 'shared/utils/validation';

import InputSearch from 'components/InputSearch';
import Input from 'components/Input';
import BackBlock from 'components/BackBlock';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import SVG from 'components/SVG';

import PopUp from './PopUp';

import './UserEdit.scss';

const b = block('user-edit');

const UserEdit = ({ match }) => {
  const dispatch = useDispatch();

  const [topUpMoney, changeTopUpMoney] = useState('');
  const [withDrawalMoney, changeWithdrawalMoney] = useState('');
  const [popUpIsOpen, setPopUpOpened] = useState(false);

  const id = match.params.id;
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const actionProcessing = useSelector(state => state.users.actionProcessing, shallowEqual);
  const usersList = useSelector(state => state.users.usersList.users, shallowEqual);
  const newPassword = useSelector(state => state.users.newPassword, shallowEqual);

  const user = useMemo(() => usersList?.find(t => +t.id === +id) || {}, [id, usersList]);

  useEffect(() => {
    if (!usersList) {
      dispatch(usersActions.getUsersList());
    }
  }, [dispatch, usersList]);

  const onChangeTopUpValue = useCallback(
    e => {
      if (!withDrawalMoney) changeTopUpMoney(e.currentTarget.value);
    },
    [topUpMoney, withDrawalMoney],
  );
  const onChangeWithdrawalValue = useCallback(
    e => {
      if (!topUpMoney) changeWithdrawalMoney(e.currentTarget.value);
    },
    [topUpMoney, withDrawalMoney],
  );

  const paymentButtonText = useMemo(() => {
    if (!topUpMoney && !withDrawalMoney) return locale.topUpWithdrawal;
    if (!topUpMoney && withDrawalMoney) return locale.withdrawal;
    if (topUpMoney && !withDrawalMoney) return locale.topUp;
  }, [locale.topUp, locale.topUpWithdrawal, locale.withdrawal, topUpMoney, withDrawalMoney]);

  const onPaymentClick = useCallback(() => {
    dispatch(
      usersActions.changeUserBalance(
        id,
        topUpMoney || withDrawalMoney,
        topUpMoney ? false : true,
        topUpMoney ? changeTopUpMoney : changeWithdrawalMoney,
      ),
    );
  }, [dispatch, id, topUpMoney, withDrawalMoney]);

  const onBanClick = useCallback(
    e => {
      if (!actionProcessing) dispatch(usersActions.changeBanState(id, true));
    },
    [actionProcessing, dispatch, id],
  );
  const onUnBanClick = useCallback(
    e => {
      if (!actionProcessing) dispatch(usersActions.changeBanState(id, false));
    },
    [actionProcessing, dispatch, id],
  );

  const onResetClick = useCallback(() => {
    const pas = generatePasswordForUser();
    if (!actionProcessing) dispatch(usersActions.resetPassword({ id, callBack: setPopUpOpened, newPassword: pas }));
  }, [actionProcessing, dispatch, id]);

  return (
    <React.Fragment>
      {popUpIsOpen && <PopUp password={newPassword} id={id} locale={locale} onClose={setPopUpOpened} />}
      <div className={b()}>
        <div className={b('back-block')}>
          <BackBlock>{locale.userEdit}</BackBlock>
        </div>
        <div className={b('content')}>
          <div className={b('inputs')}>
            <div className={b('field')}>
              <div className={b('field-title')}>{locale.id}</div>
              {/* <div className={b('field-input')}>{user.id}</div> */}
              <div className={b('field-input')}>
                <Input type="text" value={user.id} onChange={() => {}} />
              </div>
            </div>
            <div className={b('field')}>
              <div className={b('field-title')}>{locale.nickname}</div>
              {/* <div className={b('field')}>{user.nickname}</div> */}
              <div className={b('field-input')}>
                <Input type="text" value={user.nickname} onChange={() => {}} />
              </div>
            </div>
            <div className={b('field')}>
              <div className={b('field-title')}>{locale.balance}</div>
              {/* <div className={b('field-input')}>{user.balance}</div> */}
              <div className={b('field-input')}>
                <Input type="text" value={user.balance} onChange={() => {}} />
              </div>
            </div>
            <div className={b('field')}>
              <div className={b('field-title')}>{locale.topUp}</div>
              <div className={b('field-input')}>
                <Input type="number" value={topUpMoney} onChange={onChangeTopUpValue} />
              </div>
            </div>
            <div className={b('field')}>
              <div className={b('field-title')}>{locale.withdrawal}</div>
              <div className={b('field-input')}>
                <Input type="number" value={withDrawalMoney} onChange={onChangeWithdrawalValue} />
              </div>
            </div>
          </div>
          <div className={b('payment-button')}>
            <Button onClick={onPaymentClick}>
              <span className={b('payment-button-text')}>{paymentButtonText}</span>
            </Button>
          </div>
          <div className={b('reset-button')}>
            <Button onClick={onResetClick} color="white">
              <span className={b('reset-button-text')}>{locale.resetPassword}</span>
            </Button>
          </div>
          <div className={b('buttons')}>
            <div className={b('ban-button')}>
              <Button onClick={onBanClick} color="red">
                <span className={b('ban-button-text')}>{locale.ban}</span>
              </Button>
            </div>
            <div className={b('unban-button')}>
              <Button onClick={onUnBanClick} color="light-blue">
                <span className={b('unban-button-text')}>{locale.unban}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserEdit;
